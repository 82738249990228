import {z} from "zod";

export const Schema = z.object({
  owner_name: z.string(),
  dog_name: z.string(),
  email: z.string().email(),
  phone: z.string(),
  subject: z.string(),
  message: z.string(),
  submitted: z.coerce.date().default(() => new Date())
})
