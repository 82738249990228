<script lang="ts" setup>
import { FormKitLazyProvider } from '@formkit/vue'
import {createZodPlugin} from "@formkit/zod";
import {Schema} from "~/utils/contact-form";
import {FetchError} from "ofetch";
import {FormKit} from "@formkit/vue";
import {CheckCircleIcon} from "@heroicons/vue/24/solid";

useSeoMeta({
  title: "Contact Us",
  description: "Get in touch with the Rosecroft team",
})

const token = ref();
const form = ref<typeof FormKit>();
const formErrors = ref<string[]>();
const success = ref(false);

// setTimeout(() => success.value = true, 2000);

const [zodPlugin, submitHandler] = createZodPlugin(
    Schema,
    async (formData, node) => {
      formErrors.value = undefined;
      try {
        await $fetch('/api/contact', {
          method: "POST",
          body: formData
        });
        success.value = true;
      } catch (e) {
        if (e instanceof FetchError) {
          switch (e.statusCode) {
            case 400:
              const ze = JSON.parse(e.message);
              node?.setZodErrors(ze);
              break;
            default:
              const msg = e.response?._data.statusMessage
                  ?? e.response?._data.message
                  ?? "An unknown error has occurred";
              let err = `${e.response?._data.statusMessage ?? e.response?._data.message ?? "An unknown error has occurred"}. If this problem continues please contact reception@rosecroftrehab.co.uk.`;
              if (e.response?.headers.has("Cf-Ray")) {
                err = `${err} Ref: ${e.response.headers.get("Cf-Ray")}`
              }
              formErrors.value = [err];
              alert(err);
              break;
          }
        }
      }
    }
)
</script>

<template>
  <div class="bg-rosecroft-alt">
    
<FormKitLazyProvider config-file="true">
<div class="container">
      <h1 class="text-center">Contact Us</h1>
      <div class="grid md:grid-cols-2 gap-5 pb-10 min-h-[40rem] items-center">
        <NuxtPicture
            :img-attrs="{class: 'h-full w-full object-cover object-center'}"
            class="w-full h-full overflow-hidden"
            src="dogs/typing-together.jpg"
            alt="A photo form above showing the hand of a person MacBook laptop, a white dog is beside them with it's paw also on the laptop"
        />
        <FormKit ref="form"
                 :actions="false"
                 :config="{ validationVisibility: 'submit' }"
                 :plugins="[zodPlugin]"
                 type="form"
                 @submit="submitHandler"
                 :errors="formErrors"
                 v-if="!success"
        >
          <FormKit label="Your name" name="owner_name" type="text"/>
          <FormKit label="Dog's name" name="dog_name" type="text"/>
          <div class="grid md:grid-cols-2 gap-5">
            <FormKit label="EMail" name="email" type="email"/>
            <FormKit label="Phone" name="phone" type="tel"/>
          </div>
          <FormKit label="Subject" name="subject" type="text"/>
          <FormKit input-class="min-h-[10em]" label="Message" name="message" type="textarea"/>
          <div class="grid grid-flow-col items-end justify-between">
            <div class="">
              &nbsp;
              <FormKitMessages/>
            </div>
            <div class="grid justify-items-end">
              <FormKit input-class="!mb-0" outer-class="!mb-0 max-w-min" type="submit"/>
            </div>
          </div>
        </FormKit>
        <div v-if="success" class="flex md:py-20 gap-5 flex-col justify-around items-center w-full">
          <CheckCircleIcon class="max-w-[10rem]"/>
          <div class="text-6xl font-bold">Success!</div>
          <div class="text-xl">Thank you, somebody will be in touch soon.</div>
        </div>
      </div>
    </div>
</FormKitLazyProvider>

  </div>
</template>

<style lang="postcss" scoped>

</style>
